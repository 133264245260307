.contact-hero {
  margin-bottom: 147px;
  .contact-hero-h1 {
    color: #000;
    font-family: Graphik;
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: 108%;
    margin-left: 80px;
    margin-top: 62px;
    letter-spacing: -1.98px;
    width: 685.552px;;
  }
  .contact-form {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    .form {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-left: 82px;
      margin-top: 62px;
      .form-div {
        display: flex;
        gap: 20px;
        .name {
          display: flex;
          padding: 15px 0px;
          width: 50%;
          align-items: center;
          border-bottom: 1px solid #000;
        }
        .name::placeholder {
          color: rgba(0, 0, 0, 0.5);
          font-family: Graphik;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 24px */
        }
      }
      .email {
        width: 100%;
        display: flex;
        width: 626px;
        padding: 15px 0px 15px 0px;
        align-items: center;
        border-bottom: 1px solid #000;
        margin: 32px 0;
      }
      .email::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-family: Graphik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
      }
      .text {
        display: flex;
        width: 628px;
        height: 120px;
        padding: 15px 0px;
        align-items: center;
        border-bottom: 1px solid #000;
        resize: none;
      }
      .text::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-family: Graphik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
    button {
      display: flex;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 31px;
      border: 1px solid #000;
      transition: 0.3s;
    }
    button:hover {
      border: 1px solid #ffcd05;
      background-color: #ffcd05;
    }
  }
}
.indiaimg {
  display: none;
}
.analog-clock {
  position: relative;
  width: 127px;
  height: 127px;
  border: 1px solid #9e9fa6;
  border-radius: 50%;
}
.div-time {
  position: absolute;
  top: 355px;
  right: 80px;
  .time-h1 {
    color: #000;
    font-family: Graphik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 122%; /* 24.4px */
    letter-spacing: -0.2px;
    margin-bottom: 32px;
  }
}
.clocks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
}
.hand {
  position: absolute;
  top: 18%;
  left: 50%;
  transform-origin: 50% 100%;
  background-color: #333;
  transition: transform 0.5s cubic-bezier(0.4, 2.6, 0.2, 1);
}
.time-zones {
  display: flex;
  align-items: center;
  gap: 43px;
}
.digital-clock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  h1 {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    color: #000;
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: mono chivo;
    font-size: 52.744px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 63.293px */
    letter-spacing: -0.527px;
  }
  p {
    color: #000;
    font-family: Graphik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 122%; /* 24.4px */
    letter-spacing: -0.2px;
    opacity: 0.5;
  }
}
.hand.hour {
  height: 30px;
  width: 2px;
  margin-top: 10px;
}
.hand.second {
  height: 40px;
  width: 1px;
  background-color: #7c7c7c;
}
.hand.minute {
  height: 35px;
  width: 1.5px;
  margin-top: 5px;
}
.regions {
  h1 {
    width: 100%;
    text-align: center;
    color: #000;
    text-align: center;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
  }
}
.working-areas {
  width: 100%;
  padding: 108px 146px;
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    p {
      color: #000;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Graphik;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
}
.our-contact {
  padding: 94px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .contacts-info {
    width: 802px;
    display: flex;
    flex-direction: column;
    gap: 66px;
  }
  .contact-head {
    color: #000;
    text-align: center;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
  }
}
.contact-details {
  display: flex;
  gap: 56px;
  div {
    display: flex;
    width: 230px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    h1 {
      color: #000;
      font-family: Graphik;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 38.4px */
      letter-spacing: -0.32px;
    }
    p {
      color: #000;
      font-family: Graphik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      border-bottom: 2px solid black;
    }
  }
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
input {
  color: rgba(0, 0, 0);
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
textarea {
  color: rgba(0, 0, 0);
  font-family: Graphik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.address-div {
  display: flex;
  gap: 139px;
  justify-content: start;
  margin-left: 13.54%;
  margin-top: 30px;
  margin-bottom: 50px;
}
.images {
  position: relative;
  .img {
    position: absolute;
    top: 0;
    visibility: hidden;
  }
}
.USA {
  text-decoration: underline;
}
.address-div2 {
  .address-head {
    color: #000;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
    margin-bottom: 66px;
  }
  .address-head2 {
    color: #000;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
    margin-bottom: 66px;
    display: none;
  }
  div {
    margin-bottom: 36px;
    h1 {
      color: #000;
      font-family: Graphik;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 38.4px */
      letter-spacing: -0.32px;
      margin-bottom: 20px;
    }
    p {
      width: 250px;
      color: #000;
      font-family: Graphik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      opacity: 0.8;
    }
  }
}
.back-drop {
  background: rgba(245, 245, 245, 0.5);
  backdrop-filter: blur(15px);
}
@media (min-width: 1900px) {
  .contact-hero {
    margin-bottom: 147px;
    .contact-hero-h1 {
      font-size: 76px;
      width: 50%;
    }
    .contact-form {
      width: 50%;
      .form {
        width: 100%;
        .form-div {
          .name {
            font-size: 24px;
          }
          .name::placeholder {
            font-size: 24px;
          }
        }
        .email {
          width: 100%;
          font-size: 24px;
        }
        .email::placeholder {
          font-size: 24px;
        }
        .text {
          width: 100%;
          font-size: 24px;
        }
        .text::placeholder {
          font-size: 24px;
        }
      }
      button {
        border-radius: 50px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 150px;
        height: 60px;
      }
    }
  }
  .div-time {
    .time-h1 {
      font-size: 24px;
    }
  }
  .digital-clock {
    h1 {
      font-size: 62.744px;
    }
    p {
      font-size: 24px;
    }
  }
  .regions {
    h1 {
      font-size: 60.202px;
    }
  }
  .working-areas {
    div {
      p {
        font-size: 30px;
      }
    }
  }
  .our-contact {
    .contacts-info {
      width: 100%;
      align-items: center;
    }
    .contact-head {
      font-size: 60.202px;
    }
  }
  .contact-details {
    div {
      width: 330px;
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  input {
    font-size: 24px;
  }
  textarea {
    font-size: 24px;
  }
  .address-div2 {
    .address-head {
      font-size: 60.202px;
    }
    .address-head2 {
      font-size: 60.202px;
    }
    div {
      h1 {
        color: #000;
        font-family: Graphik;
        font-size: 40px;
      }
      p {
        width: 300px;
        font-size: 20px;
      }
    }
  } 
}
@media (min-width: 2500px) {
  .contact-hero {
    .contact-hero-h1 {
      font-size: 90px;
      width: 60%;
    }
    .contact-form {
      width: 60%;
      .form {
        width: 100%;
        .form-div {
          .name {
            font-size: 30px;
          }
          .name::placeholder {
            font-size: 30px;
          }
        }
        .email {
          font-size: 30px;
          width: 100%;
        }
        .email::placeholder {
          font-size: 30px;
        }
        .text {
          font-size: 30px;
          width: 100%;
        }
        .text::placeholder {
          font-size: 30px;
        }
      }
      button {
        border-radius: 50px;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 180px;
        height: 60px;
      }
    }
  }
  .div-time {
    .time-h1 {
      font-size: 30px;
    }
  }
  .digital-clock {
    h1 {
      font-size: 70px;
    }
    p {
      font-size: 30px;
    }
  }
  .regions {
    h1 {
      font-size: 70.202px;
    }
  }
  .working-areas {
    div {
      p {
        font-size: 35px;
      }
    }
  }
  .our-contact {
    .contacts-info {
      width: 100%;
      align-items: center;
    }
    .contact-head {
      font-size: 70.202px;
    }
  }
  .contact-details {
    div {
      width: 380px;
      h1 {
        font-size: 50px;
      }
      p {
        font-size: 24px;
      }
    }
  }
  input {
    font-size: 30px;
  }
  textarea {
    font-size: 30px;
  }
  .address-div2 {
    .address-head {
      font-size: 70.202px;
    }
    .address-head2 {
      font-size: 70.202px;
    }
    div {
      h1 {
        color: #000;
        font-family: Graphik;
        font-size: 50px;
      }
      p {
        width: 420px;
        font-size: 30px;
      }
    }
  } 
}
@media (max-width: 1220px) {
  .contact-hero {
    .contact-form {
      .form {
        .form-div {
          width: 428px;
          gap: 20px;
          .name {
            padding: 15px 0px;
            width: 100%;
          }
        }
        .email {
          width: 428px;
          padding: 15px 250px 15px 0px;
        }
        .text {
          width: 428px;
          padding: 15px 150px 80px 0px;
        }
      }
    }
  }
  .working-areas {
    gap: 40px;
  }
  .address-div {
    margin-left: 100px;
  }
}
@media (max-width: 1024px) {
  .contact-hero {
    .contact-hero-h1 {
      margin-left: 50px;
    }
    .div-time {
      margin-left: 50px;
    }
    .contact-form {
      .form {
        .form-div {
          gap: 20px;
          width: 100%;
          flex-direction: column;
        }
        .email {
          width: 328px;
          padding: 15px 0px;
        }
        .text {
          width: 328px;
          padding: 15px 0px 80px 0px;
        }
      }
      button {
        padding: 8px 25px;
      }
    }
  }
  .working-areas {
    gap: 20px;
  }
  .address-div {
    gap: 50px;
  }
}
@media (max-width: 950px) {
  .time-zones {
    gap: 30px;
    align-items: center;
    justify-content: center;
  }
  .USA {
    text-decoration: none;
  }
  .images {
    display: none;
  }
  .indiaimg {
    display: block;
  }
  .clocks {
    justify-content: center;
    align-items: center;
  }
  .digital-clock {
    gap: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 30px;
    }
  }
  .regions {
    h1 {
      font-size: 36px;
    }
  }
  .div-time {
    .time-h1 {
      margin-bottom: 20px;
      padding-left: 50px;
    }
  }
  .working-areas {
    flex-direction: column;
    gap: 50px;

    div {
      gap: 20px;
    }
  }
  .address-div {
    margin: 0 82px;
    flex-direction: column;
    margin-bottom: 0;
    .address-div2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h1 {
        margin-bottom: 30px;
        font-size: 36px;
      }
    }
  }
  .contact-details {
    display: flex;
    gap: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-hero {
    .div-time {
      margin-left: 0;
    }
    .contact-hero-h1 {
      font-size: 60px;
      width: 89%;
      margin-top: 120px;
      margin-left: 6%;
    }
  }
  .address-div {
    .address-div2 {
      align-items: center;
      justify-content: start;
      text-align: left;
    }
  }
}
@media (max-width: 680px) {
  .address-head2 {
    display: block;
  }
  .div-time {
    position: relative;
    top: 30px;
    right: 0px;
  }
  .clocks {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .digital-clock {
    width: 200px;
  }
  .contact-hero {
    .contact-hero-h1 {
      font-size: 40px;
      margin-left: 5%;
      width: 330px;
    }
  }
  .contact-hero {
    .contact-form {
      width: 100%;
      .form {
        margin-left: 5%;
        width: 90%;
        .form-div {
          gap: 20px;
          flex-direction: column;
        }
        .email {
          width: 100%;
          padding: 15px 0px;
        }
        .text {
          width: 100%;
          padding: 15px 0px 80px 0px;
        }
      }
    }
  }
  .working-areas {
    padding: 70px;
  }
  .regions {
    h1 {
      font-size: 40px;
    }
  }
  .address-div2 {
    .address-head {
      font-size: 40px;
    }
  }
  .address-div {
    margin: 0 70px;
  }
  .our-contact {
    .contact-head {
      font-size: 40px;
    }
  }
  .div-time {
    .time-h1 {
      padding-left: 5%;
    }
  }
}
@media (max-width: 440px) {
  .overflo {
    overflow: scroll;
  }
  .contact-hero {
    .contact-hero-h1 {
      font-size: 50px;
      width: 91%;
      margin-top: 45px;
    }
    .contact-form {
      button {
        width: 100%;
      }
    }
  }
  .regions {
    h1 {
      font-size: 24px;
      text-align: left;
      padding-left: 5%;
    }
  }
  .working-areas {
    padding: 50px;
    padding-left: 70px;
    padding-right: 70px;
    display: flex;
    div {
      width: 100%;
      height: auto;
      p {
        font-size: 16px;
      }
    }
  }
  .address-div {
    margin: 0 30px;
  }
  .contact-form {
    margin-top: 50px;
    padding-bottom: 80px;
  }
  .div-time {
    padding-bottom: 50px;
  }
  .our-contact {
    .contact-head {
      text-align: left;
      padding-left: 5%;
      font-size: 36px;
    }
  }
  .contact-details {
    align-items: start;
    padding-left: 5%;
    div {
      align-items: start;
      h1 {
        font-size: 24px;
      }
    }
  }
  .address-div {
    margin: 30px 5%;
    .address-div2 {
      .address-head {
        width: 100%;
        font-size: 24px;
      }
      div {
        h1 {
          font-size: 32px;
        }
      }
    }
  }
  .our-contact {
    padding: 0;
  }
}
@media (max-width: 380px) {
  .contact-hero .contact-hero-h1 {
    margin-left: 5%;
    width: 90%;
  }
  .time-zones {
    gap: 10px;
  }
}
@media (max-width: 1024px) and (min-width: 681px) {
  .div-time {
    position: relative;
    top: 30px;
    right: 0px;
  }
  .clocks {
    flex-direction: row;
    padding: 0px 20px;
  }

  .contact-hero .contact-form .form .form-div {
    gap: 20px;
    flex-direction: row;
  }

  .form {
    width: 100%;
  }

  .contact-hero .contact-form .form .email {
    width: 100%;
    padding: 15px 0px;
  }

  .contact-hero .contact-form .form .text {
    width: 100%;
    padding: 15px 0px 80px 0px;
  }

  .contact-hero .contact-form {
    width: 100%;
    gap: 0;
    margin-bottom: 80px;
  }

  .contact-hero .contact-form .form {
    margin-left: 0;
    padding: 50px;
  }
}
@media (max-width: 370px) {
  .div-time {
    transform: scale(0.9);
  }
}
