.lets-talk {
  font-size: 258px;
  margin: 0;
  padding: 10px;
  padding-bottom: 0;
  display: inline-block;
  position: relative;
  color: white;
  text-shadow: 1px 1px 0px black, -1px -1px 0px black, -1px 1px 0px black,
    1px -1px 0px black;
  background-position: 0 100%;
  transition: all 0.5s ease-in-out;
}
.lets-talk:hover {
  background-position: 0 0;
  color: black;
  text-shadow: 0px 0px 0px black, 0px 0px 0px black;
}
.bottom-line {
  border: 2px solid black;
  transition: all 0.5s ease-in-out;
  margin-left: 31px;
}
.lets-talk:hover ~ .bottom-line {
  background-color: black;
  width: 1039px;
}
.scroll-to-top-button {
  z-index: 99;
  position: absolute;
  top: 89.78px;
  right: 80px;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.goup {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 15px;
  transition: all 1s ease;
}
.bottonn {
  transition: all 1s ease;
}
.bottonn:hover .goup {
  top: -16px;
}
.bottonn:hover .topto {
  text-decoration: underline;
}
@media (max-width: 1280px) {
  .scroll-to-top-button {
    top: 19px;
  }
}
@media (max-width: 1229px) {
  .scroll-to-top-button {
    bottom: 830px;
  }
  .lets-talk {
    font-size: 160px;
  }
  .bottom-line {
    margin-left: 21px;
    height: 12px;
    border: 1px solid black;
  }
  .lets-talk:hover ~ .bottom-line {
    width: 620px;
  }
}
@media (max-width: 1024px) {
  .lets-talk {
    font-size: 149px;
    color: black !important;
    text-shadow: 0px 0px 0px black, 0px 0px 0px black;
  }
  .bottom-line {
    background-color: black;
    width: 610px;
    height: 10px;
    margin-left: 20px;
  }
  .lets-talk:hover ~ .bottom-line {
    width: 610px;
  }
  .scroll-to-top-button {
    bottom: 680px;
  }
}
@media (max-width: 768px) {
  .lets-talk {
    font-size: 138px;
  }
  .bottom-line {
    width: 560px;
  }
  .lets-talk:hover ~ .bottom-line {
    width: 560px;
  }
  .scroll-to-top-button {
    bottom: 610px;
    right: 0px;
  }
}

@media (max-width: 639px) {
  .lets-talk {
    font-size: 90px;
    color: black !important;
  }
  .scroll-to-top-button {
    bottom: 805px;
    top: auto;
  }
  .bottom-line {
    width: 380px;
    height: 7px;
    margin-left: 15px;
  }
  .lets-talk:hover ~ .bottom-line {
    width: 380px;
  }
}
@media (max-width: 440px) {
  .lets-talk {
    font-size: 63px;
    color: black !important;
  }
  .letter-spacing-7 {
    letter-spacing: -0.64px;
  }
  .bottom-line {
    width: 270px;
    height: 5.71px;
    margin-left: 13px;
  }
  .lets-talk:hover ~ .bottom-line {
    width: 270px;
  }
}