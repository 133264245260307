html {
  scroll-behavior: smooth;
}
#prod-hero {
  .prod-hero-h1 {
    color: #000;
    font-family: Graphik;
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: 108%; /* 71.28px */
    letter-spacing: -1.98px;
    display: flex;
    padding: 142px 0px 25px 0px;
    margin-left: 80px;
    width: 738px;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 726.649px;
      height: 696.221px;
      margin-top: 40px;
      margin-bottom: 73px;
    }
  }
  p {
    color: #000;
    font-family: Graphik;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 43.2px */
    letter-spacing: -1.08px;
    width: 588.858px;
    margin: 80px 0 48px 80px;
  }
  button {
    font-family: Graphik;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-transform: capitalize;
    display: inline-flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 31px;
    border: 1px solid #000;
    margin-left: 80px;
    margin-bottom: 147px;
    transition: 0.3s;
  }
  button:hover {
    background: #ffcd05;
    border: 1px solid #ffcd05;
  }
}
.exp-design {
  padding: 0px 0 0 80px;
  h2 {
    font-family: Archivo;
    font-size: 14.313px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.6;
    text-transform: uppercase;
    display: flex;
    width: 521.422px;
    align-items: center;
    gap: 13.419px;
    margin-bottom: 30px;
    span {
      width: 254.003px;
      height: 0.895px;
      opacity: 0.6;
      background: #000;
    }
  }
  h1 {
    color: #000;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
    width: 407.519px;
    margin-bottom: 22px;
  }
  .exp-p {
    color: #000;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    width: 536.019px;
    height: 85.152px;
    opacity: 0.8;
    margin-bottom: 42px;
  }
  .prod-container {
    display: inline-flex;
    align-items: flex-start;
    gap: 52px;
    margin-bottom: 75px;
    .prod-sub-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      width: 270px;
      h3 {
        color: #000;
        font-family: Graphik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
        letter-spacing: -0.2px;
      }
      p {
        color: #000;
        font-family: Graphik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        opacity: 0.8;
        margin-top: 8px;
      }
    }
  }
  .prod-container-2 {
    display: inline-flex;
    gap: 128px;
    margin: 40px 0;
    .prod-sub-container-2 {
      margin-top: 51px;
      width: 363.817px;
      h4 {
        margin-bottom: 34px;
        color: #000;
        font-family: Graphik;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.96px;
      }

      button {
        font-family: Graphik;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
        display: inline-flex;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 31px;
        border: 1px solid #000;
        transition: 0.3s;
      }
      button:hover {
        background: #ffcd05;
        border: 1px solid #ffcd05;
      }
    }
  }
}
#prod-engg {
  padding: 66px 0 0 0px;
  h2 {
    font-family: Archivo;
    font-size: 14.313px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.6;
    text-transform: uppercase;
    display: flex;
    width: 521.422px;
    align-items: center;
    gap: 13.419px;
    margin-bottom: 30px;
    margin-left: 80px;
    span {
      width: 254.003px;
      height: 0.895px;
      opacity: 0.6;
      background: #000;
    }
  }
  h1 {
    color: #000;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
    width: 452.519px;
    margin-bottom: 22px;
    margin-left: 80px;
  }
  .exp-p {
    color: #000;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    width: 536.019px;
    opacity: 0.8;
    margin-bottom: 42px;
    margin-left: 80px;
  }
  h3 {
    margin-bottom: 33px;
    margin-left: 80px;
  }
  .engg-container {
    display: flex;
    gap: 20px;
    margin-left: 80px;
    .engg-sub-contaner {
      display: inline-flex;
      padding: 38px 25.951px;
      flex-direction: column;
      justify-content: start;
      gap: 42px;
      align-items: flex-start;
      width: 305px;
      height: 359px;
      background: rgba(245, 245, 245, 0.9);
      backdrop-filter: blur(15.199999809265137px);
      img {
        width: 57px;
        height: 57px;
      }
      h4 {
        height: 24px;
        color: #000;
        font-family: Graphik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
        letter-spacing: -0.2px;
      }
      p {
        color: #000;
        font-family: Graphik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        opacity: 0.8;
      }
    }
  }
}
.explore-more {
  padding-bottom: 176px;
  .swiper-pagination-bullet-active {
    fill: #ffcd05 !important;
    background-color: #ffcd05 !important;
  }
  .swiper-pagination-bullet {
    width: 10.81px !important;
    height: 10.81px !important;
    margin: 0 !important;
  }
  .swiper-pagination {
    display: inline-flex;
    gap: 6px;
    position: relative;
    justify-content: center;
    top: 0px !important;
  }
  h1 {
    color: #000;
    font-family: Graphik;
    font-size: 49.202px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 59.042px */
    letter-spacing: -1.476px;
    margin: 100px 0 66px 80px;
  }
  .swiper {
    width: 100% !important;
    .swiper-slide {
      width: 298px;
      margin-left: 80px !important;
      margin-right: 3px !important;
    }
    .swiper-slide:last-child {
      margin-right: 80px !important;
    }
  }
  .exp-slides {
    width: 298px;
    img {
      height: 360px;
      width: 100%;
      object-fit: cover;
    }
    h6 {
      color: #000;
      font-family: Graphik;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-top: 30px;
    }
  }
}
.animation-tree {
  background-color: #fff;
  padding-top: 100px;
  margin: 44px 0;
  padding-left: 80px;
  .at-h1 {
    color: #000;
    font-family: Graphik;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 43.2px */
    letter-spacing: -1.08px;
    width: 567px;
    position: absolute;
    left: 513px;
  }
  p {
    display: flex;
    width: 265px;
    height: 108px;
    padding: 0px 52.658px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 11px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    backdrop-filter: blur(15.199999809265137px);
    color: #000;
    text-align: center;
    font-family: Graphik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    letter-spacing: -0.2px;
  }
  .ddt {
    display: inline-flex;
    img {
      margin-left: -5px;
      position: relative;
      z-index: 5;
      width: 80px;
    }
  }
  .dsm {
    position: relative;
    left: 680px;
    width: 265px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 13px;
      margin-top: -5px;
      position: relative;
      z-index: 5;
    }
  }
  .ia {
    width: 265px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-top: -5px;
      position: relative;
      z-index: 5;
      width: 13px;
    }
  }
}
.outter-circle {
  position: relative;
  top: -168px;
  left: 160px;
  width: 383.367px;
  height: 383.367px;
  flex-shrink: 0;
  border-radius: 383.367px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.middle-circle {
  width: 321.319px;
  height: 321.319px;
  border-radius: 321.319px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-circle {
  width: 263.369px;
  height: 263.369px;
  border-radius: 263.369px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrcurve {
  position: relative;
  left: 405px;
  top: -95px;
}
.talk {
  position: relative;
  left: 700px;
  top: -300px;
  width: 305px;
  .talk-text {
    color: #000;
    font-family: Graphik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    letter-spacing: -0.2px;
    width: 305px;
    margin-bottom: 25px;
  }
  button {
    font-family: Graphik;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-transform: capitalize;
    display: inline-flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 31px;
    border: 1px solid #000;
    transition: 0.3s;
  }
  button:hover {
    background: #ffcd05;
    border: 1px solid #ffcd05;
  }
}
.hover {
  width: 100%;
  height: 681px;
  position: absolute;
  right: 0;
  margin-top: 270px;
  z-index: 10;
  background-color: transparent;
}
@media (min-width: 1900px) {
  #prod-hero {
    .prod-hero-h1 {
      font-size: 76px;
    }
    div {
      img {
        width: 65%;
        height: auto;
      }
    }
    p {
      font-size: 40px;
      width: 688.858px;
    }
    button {
      font-size: 20px;
      border-radius: 50px;
    }
  }
  .exp-design {
    width: 100%;
    height: auto;
    h2 {
      font-family: Archivo;
      font-size: 16px;
      width: 90%;
    }
    h1 {
      font-size: 59.202px;      
      width: 100%;
    }
    .exp-p {
      font-size: 20px;
      width: 80%;
      height: auto;
    }
    .prod-container {
      .prod-sub-container {
        gap: 12px;
        width:25%;
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 20px;
        }
      }
    }
    .prod-container-2 {
      .prod-sub-container-2 {
        width: 463.817px;
        h4 {
          font-size: 40px;
        }

        button {
          font-size: 20px;
          border-radius:50px;
        }
      }
    }
  }
  #prod-engg {
    width: 100%;
    height: auto;
    h2 {
      font-size: 16px;
      width: 100%;
    }
    h1 {
      font-size: 59.202px;
      width: 100%;
    }
    .exp-p {
      font-size: 20px;
      width: 80%;
    }
    h3 {
      font-size: 20px;
    }
    .engg-container {
      .engg-sub-contaner {
        width: 25%;
        height: auto;
        h4 {
          height: 30px;
          font-size: 24px;
        }
        p {
          font-size: 20px;
          width: 100%;
        }
      }
    }
  }
  .explore-more {
    h1 {
      font-size: 59.202px;
    }
    .swiper {
      .swiper-slide {
        width: 30%;
      }
    }
    .exp-slides {
      width: 100%;
      img {
        height: 600px;
        object-fit: cover;
        width: 100%;
      }
      h6 {
        font-size: 24px;
        margin-top: 20px;
      }
    }
  }
  .animation-tree {
    .at-h1 {
      font-size: 40px;
    }
    p {
      display: flex;
      width: 305px;
      height: 128px;
      font-size: 24px;
    }
    .dsm {
      position: relative;
      left: 760px;
      width: 305px;
    }
    .ia {
      width: 305px;
    }
  }
  .outter-circle {
    left: 250px;
  }
  .inner-circle {
    font-size: 20px;
  }
  .arrcurve {
    left: 485px;
  }
  .talk {
    position: relative;
    left: 780px;
    .talk-text {
      font-size: 24px;
      width: 355px;
    }
    button {
      font-size: 20px;
      border-radius: 50px;
      width: 320px;
    }
  }
}
@media (min-width: 2500px) {
  #prod-hero {
    .prod-hero-h1 {
      font-size: 90px;
      width: 60%;
    }
    div {
      img {
        width: 60%;
        height: auto;
      }
    }
    p {
      font-size: 50px;
      width: 788.858px;
    }
    button {
      font-size: 24px;
      border-radius: 80px;
    }
  }
  .exp-design {
    h2 {
      font-family: Archivo;
      font-size: 20px;
    }
    h1 {
      font-size: 69.202px;      
    }
    .exp-p {
      font-size: 24px;
    }
    .prod-container {
      .prod-sub-container {
        h3 {
          font-size: 30px;
        }
        p {
          font-size: 24px;
        }
      }
    }
    .prod-container-2 {
      .prod-sub-container-2 {
        width: 603.817px;
        h4 {
          font-size: 50px;
        }

        button {
          font-size: 24px;
          border-radius:80px;
        }
      }
    }
  }
  #prod-engg {
    h2 {
      font-size: 20px;
      width: 100%;
    }
    h1 {
      font-size: 69.202px;
      width: 100%;
    }
    .exp-p {
      font-size: 24px;
    }
    h3 {
      font-size: 25px;
    }
    .engg-container {
      .engg-sub-contaner {
        h4 {
          height: 40px;
          font-size: 30px;
        }
        p {
          font-size: 24px;
        }
      }
    }
  }
  .explore-more {
    h1 {
      font-size: 69.202px;
    }
    .exp-slides {
      width: 100%;
      img {
        height: 700px;
        object-fit: cover;
        width: 100%;
      }
      h6 {
        font-size: 30px;
      }
    }
  }
  .animation-tree {
    .at-h1 {
      font-size: 50px;
    }
    p {
      display: flex;
      width: 405px;
      height: 148px;
      font-size: 30px;
    }
    .dsm {
      position: relative;
      left: 960px;
      width: 405px;
    }
    .ia {
      width: 405px;
    }
  }
  .outter-circle {
    left: 450px;
  }
  .inner-circle {
    font-size: 24px;
  }
  .arrcurve {
    left: 685px;
  }
  .talk {
    position: relative;
    left: 980px;
    .talk-text {
      font-size: 30px;
      width: 355px;
    }
    button {
      font-size: 24px;
      border-radius: 80px;
      width: 380px;
    }
  }
}
@media (max-width: 1127px) {
  #prod-engg {
    .engg-container {
      flex-wrap: wrap;
    }
  }
  .animation-tree {
    padding-top: 20px;
    transform: scale(0.7);
  }
}
@media (max-width: 1024px) {
  #prod-hero {
    div {
      padding: 0px 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .prod-hero-h1 {
      margin-left: 40px;
    }
    p {
      margin-left: 40px;
    }
    button {
      margin-left: 40px;
    }
  }
  .explore-more {
    h1 {
      margin-left: 40px;
    }
    .swiper {
      width: 100% !important;
      .swiper-slide {
        margin-left: 40px !important;
        margin-right: 27.55px !important;
      }
      .swiper-slide:last-child {
        margin-right: 40px !important;
      }
    }
  }
  .exp-design {
    padding-left: 40px;
    .prod-container-2 {
      flex-wrap: wrap-reverse;
      justify-content: center;
      .prod-sub-container-2 {
        width: 100%;
      }
    }
    .prod-container {
      flex-wrap: wrap;
    }
  }
  .animation-tree {
    padding-top: 20px;
    padding-left: 40px;
    transform: scale(1);
    .at-h1 {
      position: relative;
      left: 0px;
      margin-bottom: 20px;
    }
    p {
      display: none;
    }
    .ddt {
      display: none;
    }
    .dsm {
      display: none;
    }
    .ia {
      display: none;
    }
  }
  .outter-circle {
    display: none;
  }
  .arrcurve {
    display: none;
  }
  .hover {
    display: none;
  }
  .talk {
    position: relative;
    left: 0px;
    top: 0px;
    width: 305px;
  }
  #prod-engg {
    h2 {
      margin-left: 40px;
    }
    h1 {
      margin-left: 40px;
    }
    .exp-p {
      margin-left: 40px;
    }
    h3 {
      margin-left: 40px;
    }
    .engg-container {
      margin-left: 40px;
    }
  }
}
@media (max-width: 840px) {
  #prod-engg {
    h2 {
      margin-left: 40px;
      width: 90%;
      span {
        width: 40%;
      }
    }
    h1 {
      margin-left: 40px;
      width: 90%;
    }
    .exp-p {
      margin-left: 40px;
      width: 90%;
    }
    h3 {
      margin-left: 40px;
    }
    .engg-container {
      margin-left: 40px;
    }
  }
  .animation-tree {
    padding-left: 40px;
    .at-h1 {
      font-size: 24px;
      width: 90%;
    }
  }
  .talk {
    width: 90%;
    .talk-text {
      font-size: 24px;
      width: 100%;
    }
  }
  #prod-hero {
    .prod-hero-h1 {
      font-size: 60px;
      margin-top: 121px;
      width: 90%;
      margin-left: 5%;
    }
    div {
      img {
        width: 100%;
        margin-top: 60px;
        height: auto;
      }
    }
    p {
      width: 70%;
      margin-left: 5%;
    }
    button {
      margin-left: 5%;
    }
  }
  .exp-design {
    padding-left: 40px;
    padding-bottom: 100px;
    .prod-container-2 {
      justify-content: start;
      .prod-sub-container-2 {
        width: 90%;
      }
      img {
        width: 90%;
      }
    }
    h2 {
      width: 90%;
    }
    .exp-p {
      width: 90%;
      height: auto;
    }
  }
  .explore-more {
    h1 {
      font-size: 24px;
      margin-left: 40px;
    }
    .exp-slides {
      width: 242px;
      img {
        height: 293px;
      }
    }
    .swiper {
      .swiper-slide {
        width: 242px;
      }
    }
  }
}
@media (max-width: 640px) {
  #prod-hero {
    .prod-hero-h1 {
      font-size: 50px;
      margin-top: 0px;
      width: 90%;
      margin-left: 20px;
      margin-bottom: 0;
      padding-top: 50px;
    }
    div {
      padding: 0 20px;
      img {
        width: 100%;
        margin-top: 23px;
        height: auto;
        margin-bottom: 46px;
      }
    }
    p {
      width: 90%;
      font-size: 24px;
      margin-left: 20px;
      margin-top: 0;
      margin-bottom: 32px;
    }
    button {
      margin-left: 20px;
      width: 90%;
      margin-bottom: 66px;
    }
  }
  .exp-design {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 42px;
    width: 100%;
    h1 {
      width: 95%;
      font-size: 36px;
    }
    .prod-container-2 {
      flex-wrap: wrap;
      gap: 65px;
      justify-content: start;
      width: 100%;
      margin-bottom: 0;
      img {
        width: 100%;
      }
      .prod-sub-container-2 {
        width: 100%;
        margin-top: 0px;
        h4 {
          width: 90%;
          font-size: 24px;
        }
        button {
          width: 100%;
        }
      }
    }
    .prod-container {
      margin-bottom: 0;
      .prod-sub-container {
        width: 95%;
      }
    }
    h2 {
      width: 100%;
      white-space: nowrap;
      span {
        width: 100%;
      }
    }
  }
  .explore-more {
    padding-bottom: 50px;
    .swiper-pagination {
      display: none;
    }
    h1 {
      font-size: 24px;
      padding-left: 20px;
      margin-left: 0;
      margin-bottom: 32px;
      margin-top: 75px;
    }
    .swiper {
      .swiper-slide {
        width: 220px;
        margin-left: 20px !important;
        margin-right: 41px;
      }
      .swiper-slide:last-child {
        margin-right: 20px !important;
      }
    }
    .exp-slides {
      width: 220px;
      img {
        height: 266px;
      }
      h6 {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.24px;
      }
    }
  }
  #prod-engg {
    padding-top: 42px;
    h2 {
      margin-left: 0px;
      padding: 0 20px;
      width: 100%;
      white-space: nowrap;
      span {
        width: 100%;
      }
    }
    h1 {
      font-size: 36px;
      margin-left: 20px;
    }
    .exp-p {
      margin-left: 20px;
      height: auto;
    }
    h3 {
      margin-left: 20px;
    }
    .engg-container {
      margin-left: 0;
      padding: 0px 20px;
      width: 100%;
      justify-content: center;
      .engg-sub-contaner {
        width: 100%;
        p {
          width: 100%;
        }
      }
    }
  }
  .animation-tree {
    padding-left: 20px;
    margin: 42px 0 84px 0;
  }
  .talk {
    button {
      width: 100%;
    }
  }
}
