.container-casestudy {
  .swiper-button-prev::after {
    content: "" !important;
    width: 168px;
    height: 27px;
    background-color: transparent;
    border-radius: 54px;
    background-position: 17px 2px;
    border: 1px solid black;
    background-image: url("./arrow.svg");
    background-repeat: no-repeat;
  }

  .swiper-button-prev {
    position: absolute;
    transform: rotate(180deg);
    top: calc(100% - 14px);
    left: calc(100% - 153px);
    width: 68px;
    border-radius: 54px;
  }
  .swiper-button-next {
    position: absolute;
    right: 0;
    top: calc(100% - 14px);
    width: 68px;
    border-radius: 54px;
  }
  .swiper-button-next:after {
    content: "" !important;
    border: 1px solid black;
    width: 168px;
    height: 27px;
    background-color: transparent;
    border-radius: 54px;
    background-position: 17px 2px;
    background-image: url("./arrow.svg");
    background-repeat: no-repeat;
  }
  .swiper-pagination{
    bottom: 0px;
    right: 0px;
    z-index: 1;
    text-align: right;
    width: calc(100% - 189px);
    font-family: graphik;
    font-size: 16px;
  }
}
.home-container {
  .swiper-pagination-progressbar {
    position: absolute;
    top: 608px;
    width: calc(50% - 200px);
    left: calc(53% + 80px);
    height: 10px;
    border-radius: 11px;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #ffcd05;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
    border-radius: 11px;
  }

  .swiper-button-prev::after {
    content: "" !important;
    width: 168px;
    height: 27px;
    background-color: #fff;
    border-radius: 54px;
    background-position: 17px 3px;
    background-image: url("./arrow.svg");
    background-repeat: no-repeat;
  }

  .swiper-button-prev {
    position: absolute;
    transform: rotate(180deg);
    top: calc(470px + 25%);
    left: calc(50% + 332px);
    width: 68px;
    border-radius: 54px;
  }
  .swiper-button-next {
    position: absolute;
    left: calc(50% + 425px);
    top: calc(470px + 25%);
    width: 68px;
    border-radius: 54px;
  }
  .swiper-button-next:after {
    content: "" !important;
    width: 168px;
    height: 27px;
    background-color: #fff;
    border-radius: 54px;
    background-position: 17px 3px;
    background-image: url("./arrow.svg");
    background-repeat: no-repeat;
  }
}
@media (min-width: 1900px) {
  .home-container {
    .swiper-button-prev {
      top: auto;
      bottom:calc(13%);
      left:auto;
      right: 12%;
    }
    .swiper-button-next {
      top: auto;
      bottom:calc(13%);
      right: 6%;
      left:auto;
    }
  }
}
@media (max-width: 1900px) {
  .home-container {
    .swiper-button-prev {
      top: calc(420px + 25%);
    }
    .swiper-button-next {
      top: calc(420px + 25%);
    }
  }
}
  @media (max-width: 1860px) {
  .home-container {
    .swiper-button-prev {
      top: calc(360px + 25%);
    }
    .swiper-button-next {
      top: calc(360px + 25%);
    }
  }
}
@media (max-width: 1367px) {
  .home-container {
    .swiper-button-prev {
      top: calc(300px + 25%);
    }
    .swiper-button-next {
      top: calc(300px + 25%);
    }
  }
}
@media (max-width: 1260px) {
  .home-container {
    .swiper-button-prev {
      top: calc(268px + 25%);
    }
    .swiper-button-next {
      top: calc(268px + 25%);
    }
  }
}
@media (max-width: 1135px) {
  .home-container {
    .swiper-button-prev {
      top: calc(228px + 25%);
    }
    .swiper-button-next {
      top: calc(228px + 25%);
    }
  }
}
@media (max-width: 999px) {
  .container-casestudy {
    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      display: none;
    }
    .swiper-pagination{
      display: none;
    }
  }
}
