.stick_list {
  .active {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.7);
  }
  .active1 {
    border-radius: 8px;
    border: 1px solid transparent;
  }
}
.overview {
  .active {
    border-radius: 8px;
    background: #f7f7f7;
    padding: 12px;
  }
}