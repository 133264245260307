.careerHero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 606px;
  gap: 67.22px;
}
.careerHero h1 {
  color: #000;
  width: 738px;
  text-align: center;
  font-size: 66px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%; /* 71.28px */
  letter-spacing: -1.98px;
}
.heroBtn {
  border-radius: 31px;
  border: 1px solid #000;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.5s;
}
.heroBtn:hover {
  background-color: #ffcd05;
  border: 1px solid #ffcd05;
}
@media only screen and (min-width: 1900px) {
  .careerHero h1 {
    width: 838px;
    font-size: 76px;
  }
  .heroBtn {
    border-radius: 50px;
    font-size: 20px;
  }
}
@media only screen and (min-width: 2500px) {
  .careerHero h1 {
    width: 980px;
    font-size: 90px;
  }
  .heroBtn {
    border-radius: 80px;
    font-size: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .careerHero {
    height: auto;
    padding: 0px 40px;
    padding-bottom: 32px;
    padding-top: 121px;
    justify-content: start;
    gap: 42px;
  }
  .careerHero h1 {
    width: 100%;
    font-size: 60px;
  }
}
@media only screen and (max-width: 468px) {
  .careerHero {
    height: auto;
    padding: 0px 20px;
    padding-bottom: 39px;
    padding-top: 50px;
    justify-content: start;
    gap: 42px;
  }
  .careerHero h1 {
    width: 100%;
    font-size: 50px;
    text-align: left;
  }
  .heroBtn {
    width: 100%;
  }
}
